import { utcFormat } from "d3-time-format";

import { dateFormatter } from "src/components/GraphElements/formatters";

import { PCRDatum } from "../types";

// Types
export type FluPrimerTarget = (typeof fluPrimerTargetOptions)[number]["value"];

export const fluPrimerTargetOptions: {
  label: string;
  value: "InfA" | "InfB" | "InfH5";
}[] = [
  { label: "Influenza A", value: "InfA" },
  { label: "Influenza B", value: "InfB" },
  { label: "Influenza H5", value: "InfH5" },
];

export const fluPrimerTargetValues = fluPrimerTargetOptions.map((o) => o.value);

// Accessors
export const getSampleDate = (d: PCRDatum) => new Date(d.sample_date);

export const getPrimerTargetLabel = (d: PCRDatum) =>
  d.primer_target?.replace("Inf", "Influenza ");

// Formatters
export const fmtDateLong = utcFormat("%b %d, %Y");

export const xTickFormat = dateFormatter({
  byDay: utcFormat("%b %-d '%y"), // "Mar 9 '23"
  byMonth: utcFormat("%b '%y"), // "Mar '23"
});
