import BiobotCard from "src/components/BiobotCard";
import { Concentration } from "src/components/GraphElements/ConcentrationSelector";
import useId from "src/hooks/useId";

import PCRSummaryBody from "../PCRSummaryBody";
import { PCRDatum } from "../types";
import { fluPrimerTargetOptions } from "./util";

export interface FluSummaryCardProps {
  data: PCRDatum[];
  setConcentrationUnit: (value: Concentration) => void;
  concentrationUnit: Concentration;
}

const FluSummaryCard = ({
  data: rawData,
  setConcentrationUnit,
  concentrationUnit,
}: FluSummaryCardProps) => {
  const titleId = useId();
  const subtitleId = useId();
  return (
    <BiobotCard
      title="Compare Influenza Strains and Subtypes across Locations"
      subtitle="Investigate patterns in influenza strain and subtype levels using this dynamic time series chart. Choose various locations and switch between influenza strains/subtypes to study fluctuations over time and in different places. This graphic tool assists in tracking and comprehending the prevalence of these viruses in the environment."
      titleId={titleId}
      subtitleId={subtitleId}
    >
      <p className="text-navy-9 mb-6">
        Note: The H5* influenza and influenza A assays target different genes
        that make data comparison between the two targets more challenging than
        using a simple ratio. We do not recommend comparing the concentration of
        influenza A to the concentration of H5N1.
      </p>
      <PCRSummaryBody
        rawData={rawData}
        targetNameOptions={fluPrimerTargetOptions}
        setConcentrationUnit={setConcentrationUnit}
        concentrationUnit={concentrationUnit}
        aria-labelledby={titleId}
        aria-describedby={subtitleId}
      />
    </BiobotCard>
  );
};

export default FluSummaryCard;
