import { Switch } from "antd";
import { Dispatch, SetStateAction, useState } from "react";

import { ChartThemeProvider, useOrdinalColorScale } from "src/bb-chart";
import BiobotCard from "src/components/BiobotCard";
import { SmallMultiples } from "src/components/ChartLayout";
import RangePickerWithPresets, {
  DateRange,
} from "src/components/DateRangePickerWithPresets";
import EmptyPlot from "src/components/EmptyPlot";
import CheckboxLegend from "src/components/GraphElements/CheckboxLegend";
import ConcentrationSelector, {
  Concentration,
} from "src/components/GraphElements/ConcentrationSelector";
import { useLocationTypeSelector } from "src/components/GraphElements/LocationTypeSelector";
import useExtent from "src/hooks/useExtent";
import useTransformedData from "src/hooks/useTransformedData";
import useUniqueValues from "src/hooks/useUniqueValues";
import { smallChartTheme } from "src/theme/chartTheme";

import { PCRDatum } from "../types";
import { fmtUnits } from "../util";
import FluLocationChart from "./FluLocationChart";
import {
  FluPrimerTarget,
  fluPrimerTargetOptions,
  fluPrimerTargetValues,
} from "./util";

interface FluLocationsBodyProps {
  data: PCRDatum[];
  setConcentrationUnit: Dispatch<SetStateAction<Concentration>>;
  concentrationUnit: Concentration;
}

export interface FluLocationsCardProps extends FluLocationsBodyProps {
  dateLastUpdated?: string;
}

const FLuLocationsBody = ({
  data: rawData_,
  concentrationUnit,
  setConcentrationUnit,
}: FluLocationsBodyProps) => {
  const { data: rawData, locationTypeControl } =
    useLocationTypeSelector(rawData_);

  const [locationNames] = useUniqueValues(rawData, ["sampling_location_name"]);
  const colorScale = useOrdinalColorScale(fluPrimerTargetValues);

  const [isStandardized, setIsStandardized] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [primerTargets, setPrimerTargets] = useState<FluPrimerTarget[]>(
    fluPrimerTargetValues,
  );

  const data = useTransformedData(rawData, {
    filter: {
      sample_date: { between: dateRange },
      primer_target: { in: primerTargets },
    },
    units: {
      effective_concentration: { from: "1/L", to: concentrationUnit },
    },
  });

  const concentrationRange = useExtent(data, "effective_concentration");

  const empty =
    primerTargets.length === 0 ? (
      <EmptyPlot status="select-type" />
    ) : (
      <EmptyPlot status="no-results-timeframe" />
    );

  return (
    <div>
      <div className="flex flex-col gap-8 pb-6 sticky top-0 bg-white z-[100]">
        <div className="flex flex-wrap items-end gap-8">
          <div className="-mb-1">
            <div className="text-p2 text-navy-9">Select Date Range</div>
            <RangePickerWithPresets value={dateRange} onChange={setDateRange} />
          </div>
          <CheckboxLegend
            value={primerTargets}
            onChange={setPrimerTargets}
            options={fluPrimerTargetOptions}
            colorScale={colorScale}
            className="[&>label]:text-p2-thin"
          />
          <ConcentrationSelector
            value={concentrationUnit}
            onChange={setConcentrationUnit}
          />
          {locationNames.length > 1 && (
            <label className="text-p2-thin flex items-center gap-2">
              <Switch
                onChange={setIsStandardized}
                checked={isStandardized}
                title="Standardize Graph Axes"
              />
              Standardize Axes
            </label>
          )}
        </div>
        {locationTypeControl}
      </div>
      <SmallMultiples.GridWithExpand
        data={data}
        groupBy="sampling_location_name"
        keys={locationNames}
        renderChart={({ data: chartData, isExpanded }) => (
          <div className="aspect-[16/9]">
            <FluLocationChart
              data={chartData}
              unitsText={fmtUnits(concentrationUnit)}
              dateRange={dateRange}
              concentrationRange={
                isStandardized ? concentrationRange : undefined
              }
              colorScale={colorScale}
              isExpanded={isExpanded}
              empty={empty}
            />
          </div>
        )}
      />
    </div>
  );
};

const FluLocationsCard = (props: FluLocationsCardProps) => (
  <BiobotCard
    title="Compare Influenza Strains and Subtypes within Individual Locations"
    subtitle="Examine area-focused time series charts in this segment, showcasing concentrations of influenza strains and subtypes. Obtain crucial knowledge about the prevalence and tendencies of these prevalent viral strains/subtypes, which hold substantial consequences for public health and infection spread patterns."
  >
    <p className="text-navy-9 mb-6">
      Note: The H5* influenza and influenza A assays target different genes that
      make data comparison between the two targets more challenging than using a
      simple ratio. We do not recommend comparing the concentration of influenza
      A to the concentration of H5N1.
    </p>
    <ChartThemeProvider value={smallChartTheme}>
      <FLuLocationsBody {...props} />
    </ChartThemeProvider>
  </BiobotCard>
);

export default FluLocationsCard;
